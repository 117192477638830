/* global subheaderSectionComponent */
class SubheaderSectionComponent extends SubheaderSectionComponent {

    mounted(){
        let superMounted = super.mounted;
        return async function() {
            await superMounted();
        };
    }

    getMethods() {
        let superMethods = super.getMethods();
        superMethods.openCustomMenu = this.openCustomMenu;
        return superMethods;
    }

    openCustomMenu() {
		this.selectedCategory = "nosotros";
    }
    getTemplate() {
        return `<nav class="navbar-expand-lg category-nav" role="navigation">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mr-auto categories">
                    <template v-for="cat in homeCategory">
                        <template v-if="getCatInfo(cat.fields.CatName).length>0">
                            <li :class="[{'show': (selectedCategory == cat),'multi-link':true },subMenuCSS]" @mouseover.prevent="isMobile ? null : openSubMenu(cat)" @click="isMobile ? handleMobileMenu(cat) : filterByCategory(cat.fields.CatCode)">
                                <span class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                            {{capitalize(cat.fields.CatName)}}
                                </span>
                                <ul :id="'navbarDropdown'+cat.fields.CatCode" :class="[{show:(selectedCategory == cat)},subMenuCSS2]"  aria-labelledby="false" @mouseover="isMobile ? null : openSubMenu(cat)" @mouseleave="isMobile ? null : closeSubMenu()">
                                  <div class="row">
                                    <div class="col category-menu">
                                      <categoryMenuComponent v-for="(subcat,keycat) in getCatInfo(cat.fields.CatName)"
                                                     :key="keycat"
                                                     :category="subcat"
                                      ></categoryMenuComponent>
                                      <div class="category-image" v-if="cat.fields.CatPhotoUri">
                                        <div class="image" v-bind:style="{backgroundImage: 'url(' + cat.fields.CatPhotoUri + ')'}"></div>
                                      </div>
                                    </div>
                                  </div>
                                </ul>
                            </li>
                        </template>
                        <template v-else>
                            <li :class="[{'show': (selectedCategory == cat), 'single-link':true },subMenuCSS]"  @click="filterByCategory(cat.fields.CatCode)" @mouseover="closeSubMenu()">
                                <span class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{capitalize(cat.fields.CatName)}}
                                </span>
                            </li>
                        </template>
                    </template>
                    <li :class="[{'show': (selectedCategory == 'nosotros')}, subMenuCSS]" @mouseover.prevent="isMobile ? null : selectedCategory = 'nosotros'" @click="isMobile ? openCustomMenu() : null">
                        <span class="nav-link dropdown-toggle" :class="{'active': currentCategory == 'nosotros'}" id="navbarDropdownNosotros" data-toggle="#menuNosotros" aria-haspopup="true" aria-labelledby="false">
                            Nosotros
                        </span>
                        <ul id="navbarDropdownNosotros" :class="[{show:(selectedCategory == 'nosotros')},subMenuCSS2]"  aria-labelledby="false" @mouseover="isMobile ? null : openCustomMenu()" @mouseleave="isMobile ? null : closeSubMenu()">
                          <div class="row">
                            <div class="col category-menu">
								<div class="item-link">
									<li class="dropdown-item">
										<span><router-link to="/campaign">CAMPAÑA</router-link></span>
									</li>
								</div>
								<div class="item-link">
									<li class="dropdown-item">
										<span><router-link to="/stores">PUNTOS TUKSON</router-link></span>
									</li>
								</div>
								<div class="item-link">
									<li class="dropdown-item">
										<span><router-link to="/contact">CONTACTO</router-link></span>
									</li>
								</div>
                            </div>
                          </div>
                        </ul>
                    </li>
                  </ul>
                </div>
              </nav>`;
    }
}

SubheaderSectionComponent.registerComponent();